export const menu = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Categories",
    url: "/categories",
  }
];

export const siteTitle = "Meatbrains";
export const siteDescription = "Search through over 100,000 study resources";
export const websiteRoot = "https://meatbrains.com";

export const maxSearchResults = 1000;
export const footerText =
  /* <Current Year> is already prefixed */ "All rights reserved";
